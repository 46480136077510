import React from "react";

import Proyectos from "../../../proyectos";

const Proyecto = ({params}) => {
  const projectIndex = params.project;
  const wbsIndex = params.wbs;
  const name = params.name;

  return <Proyectos name={name} projectIndex={projectIndex} wbsIndex={wbsIndex} />;
};

export default Proyecto;
